import jsPDF from 'jspdf';
import 'jspdf-autotable';
var pdf = new jsPDF('p', 'mm', [297, 210]);

class PDFs {


    Tabla(margen,altura,titulos,filas){
        pdf.autoTable({
            head: titulos,
            body: filas,
            startY: altura,
            margin: margen,
            headerStyles: {fillColor: [255, 255, 255], fontSize: 8, textColor: [0,0,0]},                     
            columnStyles: {
                text: {columnWidth: 'auto'}, 
                2: {halign: "left"},
                //0: {halign: "right"},
                fillColor: [255, 255, 255], textColor: [0,0,0]
            },             
            styles: {
                //font: 'courier',
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                fontSize: 8,
                fillColor: [255, 255, 255], 
                textColor: [0,0,0],
                halign: 'center', // left, center, right
            },
        });
    }

    Out(){
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(pdf.output("blob"),"consulta.pdf");
        } else {
            //pdf.autoPrint();
            window.open(
                URL.createObjectURL(pdf.output("blob")),
                "_blank",
                "height=650,width=500,scrollbars=yes,location=yes"
            );
            setTimeout(() => {    
                window.URL.revokeObjectURL(pdf.output("bloburl"));
            }, 100);
        }
        pdf = new jsPDF();      
    }

    moneda(number){
        return new Intl.NumberFormat("de-DE").format(parseFloat(number).toFixed(2));
    }

    convfech(fecha){
        return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
    }
   
    PreFactura(data) {
        pdf = new jsPDF('l', 'mm', [210, 145]);
        pdf.addImage("images/logo.jpg", 'JPEG', 10, 5, 45, 15);

        pdf.setFontSize(10);
        pdf.setFont("italic", "bold");
        pdf.text(100, 10, 'Unidad de Reahabilitación y Fisioterapia', 'center');
        pdf.text(100, 13, 'Integral del Mar, C.A.', 'center');
        pdf.setFont("italic", "normal");
        pdf.setFontSize(8);
        pdf.text(100, 16, 'RIF. J - 411170046', 'center');
        pdf.setFontSize(6);
        pdf.text(100, 18, 'Calle Camaiguana entre Tajalí y Calamar, Casa N°, NF - 2A', 'center');
        pdf.text(100, 20, 'Urb. Mar, Lechería Edo. Anzoátegui. Zona Postal 6016', 'center');

        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        pdf.text(100, 27, 'RECIBO', 'center');
        pdf.setFontSize(14);
        pdf.setFont("italic","normal");
        pdf.rect(137, 115, 63, 18); 

        pdf.rect(155, 13, 43, 10); 
        pdf.text('Nº', 160, 20);
        pdf.text(data.id.toString().padStart(8, 0), 170, 20);
        //pdf.text(data.control.toString().padStart(8, 0), 175, 20);
        pdf.setFontSize(10);
        pdf.text('Fecha de emisión: '+data.fechad, 155, 10);
        pdf.rect(10, 30, 190, 18); 
        pdf.text('Nombre o Razón Social: '+data.cliente, 12, 35);
        pdf.text('Rif: '+data.rif, 150, 35);
        pdf.text('Dirección: '+data.direccion, 12, 40, {maxWidth: 145, align: "justify"});
        pdf.text('Correo: '+data.correo, 12, 45);
        pdf.text('N° de teléfono: '+data.celular, 150, 45);

        var produc = data.detalle;
        const head = [['CANT.','CONCEPTO O DESCRIPCIÓN','P.UNITARIO','TOTAL']];
        var body = [];
        var sec = ' ';
        for (var i = 0; i < produc.length; i++) {
            if(produc[i].sec){sec = ' '+produc[i].sec+' ';} else {sec = ' ';}
			body.push([
                1,
                produc[i].producto+sec+this.convfech(produc[i].fecha),
                this.moneda(parseFloat(produc[i].monto)*parseFloat(data.tasa)),
                this.moneda(parseFloat(produc[i].monto)*parseFloat(data.tasa)),
            ]);
        }

        pdf.autoTable({
            head: head,
            body: body,
            startY: 50,
            margin: 10,
            headerStyles: {fillColor: [255, 255, 255], fontSize: 8, textColor: [0,0,0]},                     
            styles: {
                fontSize: 8,
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                halign: 'center', // left, center, right
                fillColor: [255, 255, 255],
                textColor: [0,0,0]
            },
            columnStyles: {
                0: {cellWidth: 15},
                1: {cellWidth: 130, halign: 'left'},
                2: {cellWidth: 20},
                3: {cellWidth: 25},
              }
        });


        pdf.setFontSize(10);
        pdf.text('SUBTOTAL:', 140, 120);
        pdf.text('Bs. '+this.moneda(parseFloat(data.montobs)), 180, 120);
        pdf.text('IVA:', 140, 125);
        pdf.text('Bs. 0', 180, 125);
        pdf.setFontSize(12);
        pdf.text('TOTAL A PAGAR:',140, 130);
        pdf.text('Bs. '+this.moneda(parseFloat(data.montobs)),180, 130);
        pdf.text('Sin Derecho a Crédito Fiscal', 10, 140);

        this.Out();
    }

    Honorarios(data) {
        pdf = new jsPDF('p', 'mm', [210, 290]);
        pdf.addImage("images/logo.jpg", 'JPEG', 10, 5, 45, 15);

        pdf.setFontSize(10);
        pdf.setFont("italic", "bold");
        pdf.text(100, 10, 'Unidad de Reahabilitación y Fisioterapia', 'center');
        pdf.text(100, 13, 'Integral del Mar, C.A.', 'center');
        pdf.setFont("italic", "normal");
        pdf.setFontSize(8);
        pdf.text(100, 16, 'RIF. J - 411170046', 'center');
        pdf.setFontSize(6);
        pdf.text(100, 18, 'Calle Camaiguana entre Tajalí y Calamar, Casa N°, NF - 2A', 'center');
        pdf.text(100, 20, 'Urb. Mar, Lechería Edo. Anzoátegui. Zona Postal 6016', 'center');


        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        pdf.text(100, 27, 'RECIBO DE HONORARIOS', 'center');
        pdf.setFontSize(14);
        pdf.setFont("italic","normal");

        pdf.rect(155, 13, 43, 10); 
        pdf.text('Nº', 160, 20);
        pdf.text(data.control, 167, 20);
        //pdf.text(data.control.toString().padStart(8, 0), 175, 20);
        pdf.setFontSize(10);
        pdf.text('Fecha de emisión: '+data.fechad, 155, 10);
        pdf.rect(10, 30, 190, 13); 
        pdf.text('Nombre o Razón Social: '+data.especialista, 12, 35);
        pdf.text('Rif: '+data.rif, 150, 35);
        pdf.text('Dirección: '+data.direccion, 12, 40, {maxWidth: 145, align: "justify"});
        pdf.text('N° de teléfono: '+data.celular, 150, 40);

        var produc = data.detalle;
        const head = [['N°','FECHA','DESCRIPCIÓN','MONTO']];
        var body = [];
        
        for (var i = 0; i < produc.length; i++) {
			body.push([
                (i+1),
                this.convfech(produc[i].fecha),
                produc[i].producto+' - '+produc[i].nombre+' '+produc[i].apellido,
                '$'+this.moneda(parseFloat(produc[i].espec)),
            ]);
        }

        pdf.autoTable({
            head: head,
            body: body,
            startY: 45,
            margin: 10,
            headerStyles: {fillColor: [255, 255, 255], fontSize: 8, textColor: [0,0,0]},                     
            styles: {
                fontSize: 8,
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                halign: 'center', // left, center, right
                fillColor: [255, 255, 255],
                textColor: [0,0,0]
            },
            columnStyles: {
                0: {cellWidth: 10},
                1: {cellWidth: 30},
                2: {cellWidth: 120, halign: 'left'},
                3: {cellWidth: 30},
              }
        });

        pdf.setFontSize(12);
        pdf.text('TOTAL:',100, 280);
        pdf.text('Bs. '+this.moneda(parseFloat((-1)*data.recibidobs)),120, 280);
        pdf.text('( $ '+this.moneda(parseFloat(data.monto))+' )',180, 280);
        pdf.text('Sin Derecho a Crédito Fiscal', 10, 280);

        this.Out();
    }

    ControlFact(data) {
        pdf = new jsPDF('l', 'mm', [210, 145]);
        pdf.addImage("images/logo.jpg", 'JPEG', 10, 5, 45, 15);

        pdf.setFontSize(10);
        pdf.setFont("italic", "bold");
        pdf.text(100, 10, 'Unidad de Reahabilitación y Fisioterapia', 'center');
        pdf.text(100, 13, 'Integral del Mar, C.A.', 'center');
        pdf.setFont("italic", "normal");
        pdf.setFontSize(8);
        pdf.text(100, 16, 'RIF. J - 411170046', 'center');
        pdf.setFontSize(6);
        pdf.text(100, 18, 'Calle Camaiguana entre Tajalí y Calamar, Casa N°, NF - 2A', 'center');
        pdf.text(100, 20, 'Urb. Mar, Lechería Edo. Anzoátegui. Zona Postal 6016', 'center');


        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        pdf.text(100, 27, 'RECIBO', 'center');
        pdf.setFontSize(14);
        pdf.setFont("italic","normal");

        pdf.rect(155, 13, 43, 10); 
        pdf.text('Nº', 160, 20);
        pdf.text(data.id.toString().padStart(8, 0), 170, 20);
        //pdf.text(data.control.toString().padStart(8, 0), 175, 20);
        pdf.setFontSize(10);
        pdf.text('Fecha de emisión: '+data.fechad, 155, 10);
        //pdf.rect(10, 30, 190, 18); 
        pdf.text('Nombre o Razón Social: '+data.cliente, 12, 30);
        pdf.text('Rif: '+data.rif, 150, 30);
        pdf.text('Dirección: '+data.direccion, 12, 35, {maxWidth: 145, align: "justify"});
        pdf.text('N° de teléfono: '+data.celular, 150, 35);
        var sec = ' ';
        var produc = data.detalle;
        const head = [['CANT.','CONCEPTO O DESCRIPCIÓN','P.UNITARIO','TOTAL']];
        var body = [];
        var desc = [];
        for (var i = 0; i < produc.length; i++) {
            if(produc[i].sec){sec = ' '+produc[i].sec+' ';} else {sec = ' ';}
            if(data.desc){
                desc = produc[i].nombre+sec+this.convfech(produc[i].fecha);
            } else {desc = produc[i].nombre;}
            body.push([
                1,
                desc,
                'Bs. '+this.moneda(parseFloat(produc[i].monto)*parseFloat(produc[i].tasa)),
                'Bs. '+this.moneda(parseFloat(produc[i].monto)*parseFloat(produc[i].tasa)),
            ]);
        }

        pdf.autoTable({
            head: head,
            body: body,
            startY: 38,
            margin: 10,
            headerStyles: {fillColor: [255, 255, 255], fontSize: 8, textColor: [0,0,0]},                     
            styles: {
                fontSize: 6,
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                halign: 'center', // left, center, right
                fillColor: [255, 255, 255],
                textColor: [0,0,0],
                rowHeight: 3,
            },
            columnStyles: {
                0: {cellWidth: 15},
                1: {cellWidth: 130, halign: 'left'},
                2: {cellWidth: 20},
                3: {cellWidth: 25},
            },
        });


        //pdf.rect(137, 125, 63, 18); 
        pdf.setFontSize(8);
        pdf.text('SUBTOTAL:', 140, 130);
        pdf.text('Bs. '+this.moneda(parseFloat(data.submonto)), 180, 130);
        pdf.text('IVA:', 140, 135);
        pdf.text('Bs. '+this.moneda(parseFloat(data.iva)), 180, 135);
        pdf.setFontSize(10);
        pdf.text('TOTAL A PAGAR:',140, 140);
        pdf.text('Bs. '+this.moneda(parseFloat(data.monto)),180, 140);
        pdf.text('Sin Derecho a Crédito Fiscal', 10, 140);

        this.Out();
    }

    Factura(data) {
        let horiz = 140;
        pdf = new jsPDF('p', 'mm', 'legal');
        //pdf.addImage("images/prueba.jpg", 'JPEG', 0, 0, 215.9, 355.6);

        pdf.setFontSize(12);
        pdf.setFont("italic", "bold");
        pdf.text('Fecha de emisión: '+data.fechad, 15, 30);
        pdf.text('Fecha de emisión: '+data.fechad, 15, 30+horiz);
        pdf.text('Nº de Factura: '+data.id, 160, 30);
        pdf.text('Nº de Factura: '+data.id, 160, 30+horiz);

        pdf.setFontSize(10);
        pdf.rect(15, 31, 190, 9); 
        pdf.rect(15, 31+horiz, 190, 9); 
        pdf.text('Nombre o Razón Social: '+data.cliente, 17, 35);
        pdf.text('Nombre o Razón Social: '+data.cliente, 17, 35+horiz);
        pdf.text('Rif: '+data.rif, 155, 35);
        pdf.text('Rif: '+data.rif, 155, 35+horiz);
        pdf.text('Dirección: '+data.direccion, 17, 39, {maxWidth: 145, align: "justify"});
        pdf.text('Dirección: '+data.direccion, 17, 39+horiz, {maxWidth: 145, align: "justify"});
        pdf.text('N° de teléfono: '+data.celular, 155, 39);
        pdf.text('N° de teléfono: '+data.celular, 155, 39+horiz);

        pdf.rect(15, 40, 190, 6); 
        pdf.rect(15, 40+horiz, 190, 6); 
        pdf.text('CANT.', 17, 44);
        pdf.text('CANT.', 17, 44+horiz);
        pdf.text('CONCEPTO O DESCRIPCIÓN', 30, 44);
        pdf.text('CONCEPTO O DESCRIPCIÓN', 30, 44+horiz);
        pdf.text('P.UNITARIO', 160, 44);
        pdf.text('P.UNITARIO', 160, 44+horiz);
        pdf.text('TOTAL', 185, 44);
        pdf.text('TOTAL', 185, 44+horiz);
        
        pdf.setFontSize(8);
        var sec = ' ';
        var produc = data.detalle;
        for (var i = 0; i < produc.length; i++) {
            if(produc[i].sec){sec = ' '+produc[i].sec+' ';} else {sec = ' ';}
                pdf.text(''+produc[i].cantidad, 20, 50+(i*4));
                pdf.text(''+produc[i].cantidad, 20, 50+(i*4)+horiz);
                pdf.text(produc[i].nombre+sec+this.convfech(produc[i].fecha), 30, 50+(i*4));
                pdf.text(produc[i].nombre+sec+this.convfech(produc[i].fecha), 30, 50+(i*4)+horiz);
       
                pdf.text('Bs. '+this.moneda(parseFloat(produc[i].monto)*parseFloat(produc[i].tasa)), 160, 50+(i*4));
                pdf.text('Bs. '+this.moneda(parseFloat(produc[i].monto)*parseFloat(produc[i].tasa)), 160, 50+(i*4)+horiz);
                pdf.text('Bs. '+this.moneda(parseFloat(produc[i].monto)*parseFloat(produc[i].tasa)), 185, 50+(i*4));
                pdf.text('Bs. '+this.moneda(parseFloat(produc[i].monto)*parseFloat(produc[i].tasa)), 185, 50+(i*4)+horiz);
        }

        pdf.setFontSize(14);
        pdf.setFont("italic","normal");
        //pdf.rect(160, 105, 45, 17); 
        //pdf.rect(160, 105+horiz, 45, 17); 
        pdf.setFontSize(10);
        pdf.text('SUBTOTAL:', 120, 100);
        pdf.text('SUBTOTAL:', 120, 100+horiz);
        pdf.text('Bs. '+this.moneda(parseFloat(data.submonto)), 170, 100);
        pdf.text('Bs. '+this.moneda(parseFloat(data.submonto)), 170, 100+horiz);
        pdf.text('IVA:', 120, 105);
        pdf.text('IVA:', 120, 105+horiz);
        pdf.text('Bs. '+this.moneda(parseFloat(data.iva)), 170, 105);
        pdf.text('Bs. '+this.moneda(parseFloat(data.iva)), 170, 105+horiz);
        pdf.setFontSize(12);
        pdf.text('TOTAL A PAGAR:',120, 110);
        pdf.text('TOTAL A PAGAR:',120, 110+horiz);
        pdf.text('Bs. '+this.moneda(parseFloat(data.monto)),170, 110);
        pdf.text('Bs. '+this.moneda(parseFloat(data.monto)),170, 110+horiz);

        this.Out();
    }

    Historial(data) {
        pdf = new jsPDF('P', 'mm', [210, 279]);
        pdf.addImage("images/logo.jpg", 'JPEG', 10, 5, 45, 15);

        pdf.setFontSize(10);
        pdf.setFont("italic", "bold");
        pdf.text(100, 10, 'Unidad de Reahabilitación y Fisioterapia', 'center');
        pdf.text(100, 13, 'Integral del Mar, C.A.', 'center');
        pdf.setFont("italic", "normal");
        pdf.setFontSize(8);
        pdf.text(100, 16, 'RIF. J - 411170046', 'center');
        pdf.setFontSize(6);
        pdf.text(100, 18, 'Calle Camaiguana entre Tajalí y Calamar, Casa N°, NF - 2A', 'center');
        pdf.text(100, 20, 'Urb. Mar, Lechería Edo. Anzoátegui. Zona Postal 6016', 'center');

        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        pdf.text(100, 50, 'INFORME  MÉDICO', 'center');
        pdf.setFontSize(12);

        pdf.text('Antecedentes', 10, 60);
        pdf.text('Motivo de Consulta', 10, 90);
        pdf.text('Examen Físico', 10, 120);
        pdf.text('Impresión Diagnóstica', 10, 150);
        pdf.text('Plan', 10, 180);
        pdf.text(110, 260, data.especialista, 'center');

        pdf.setFont("italic", "normal");
        pdf.text('Paciente: '+data.paciente, 10, 30);
        pdf.text('Fecha: '+data.fecha, 165, 30);
        pdf.text('C.I: '+data.cedula, 10, 35);
        pdf.text('Edad: '+data.edad+' años.', 10, 40);

        pdf.text(data.antecedente, 15, 65, {maxWidth: 180, align: "justify"});
        pdf.text(data.motivo, 15, 95, {maxWidth: 180, align: "justify"});
        pdf.text(data.examenfisico, 15, 125, {maxWidth: 180, align: "justify"});
        pdf.text(data.diagnostico, 15, 155, {maxWidth: 180, align: "justify"});
        pdf.text(data.tratamiento, 15, 185, {maxWidth: 180, align: ""});

        this.Out();
    }

    Indicaciones(data) {
        pdf = new jsPDF('l', 'mm', [210, 145]);
        pdf.addImage("images/logo.jpg", 'JPEG', 5, 5, 45, 15);
        pdf.addImage("images/logo.jpg", 'JPEG', 110, 5, 45, 15);

        pdf.setFont("italic", "normal");
        pdf.setFontSize(6);
        pdf.text(100, 9, 'Calle Camaiguana entre Tajalí y Calamar,', 'right');
        pdf.text(100, 11.5, 'Casa N°, NF - 2A', 'right');
        pdf.text(100, 14, 'Urb. Mar, Lechería Edo. Anzoátegui.', 'right');
        pdf.text(100, 16.5, 'Zona Postal 6016', 'right');

        pdf.text(205, 9, 'Calle Camaiguana entre Tajalí y Calamar,', 'right');
        pdf.text(205, 11.5, 'Casa N°, NF - 2A', 'right');
        pdf.text(205, 14, 'Urb. Mar, Lechería Edo. Anzoátegui.', 'right');
        pdf.text(205, 16.5, 'Zona Postal 6016', 'right');

        pdf.setFont("italic", "bold");
        pdf.setFontSize(8);
        pdf.text(100, 20, 'RIF. J - 411170046', 'right');
        pdf.text(205, 20, 'RIF. J - 411170046', 'right');

        pdf.rect(5, 25, 95, 115); 
        pdf.rect(110, 25, 95, 115); 

        pdf.setFontSize(12);
        pdf.setFont("italic", "bold");
        pdf.text(55, 30, 'RECIPE', 'center');
        pdf.text(160, 30, 'INDICACIONES', 'center');

        pdf.setFontSize(8);
        pdf.setFont("italic", "normal");
        pdf.text(95, 35, 'Fecha: '+data.fecha, 'right');
        pdf.text(200, 35, 'Fecha: '+data.fecha, 'right');

        pdf.setFontSize(9);

        pdf.text(10, 35,'Paciente: '+data.paciente, 'left');
        pdf.text(115, 35, 'Paciente: '+data.paciente, 'left');

        pdf.text(10, 40,'Cédula: '+data.cedula, 'left');
        pdf.text(115, 40, 'Cédula: '+data.cedula, 'left');

        pdf.text(data.medicamento, 15, 50, {maxWidth: 100, align: "justify"});
        pdf.text(data.tratamiento, 120, 50, {maxWidth: 100, align: "justify"});

        pdf.setFontSize(10);
        pdf.setFont("italic","normal");
        pdf.text(55, 135, data.especialista, 'center');
        pdf.text(160, 135, data.especialista, 'center');

        this.Out();
    }






}

export default PDFs;